<template>
  <v-app>
    <headerMenu ></headerMenu>
    <index-menu :key="forceRerender">
    </index-menu>
    <v-main>
      <router-view  v-on:changeNavbar="updateNavbar()"></router-view>
    </v-main>

<!--    <footer-view></footer-view>-->
  </v-app>
</template>

<script>

import IndexMenu from "@/components/IndexMenu.vue";
// import FooterView from "@/components/footerView.vue";
import HeaderMenu from "@/components/HeaderMenu.vue";

export default {
  name: 'App',
  components:{
    HeaderMenu,
    IndexMenu,
    // FooterView
  },

  data(){
    return{
      forceRerender: 0,
    }
  },
  methods: {
    updateNavbar() {
      // this.$router.go(0);
      // this.forceRerender += 1
    }
  }
};
</script>
<style>


</style>
