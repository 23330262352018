<template>
  <nav class = "header">
    <div v-if="this.$store.state.token">
    <v-btn name = "toolbar" @click = menuchange(drawer)>Menu</v-btn>
      <v-btn name = "toolbar" class = "profile" >{{userinfo.username}}</v-btn>
    <v-btn @click="logout" class = "Btn-logout">Logout</v-btn>
    </div>
    <div v-else>
      <v-btn name = "toolbar" @click = menuchange(drawer)>Menu</v-btn>
      <v-btn class = "Btn-logout" ><router-link to="/registration">new account</router-link></v-btn>
      <v-btn class = "Btn-logout" ><router-link :to="{ name:'login'}">Login</router-link></v-btn>
    </div>
  </nav>
</template>


<script>

import { bus } from '../main';
import axios from "@/axios-config";
export default {
  name: "Headermenu",

  data() {
    return {
      token: this.$store.state.token,
      userinfo: [

      ]
    }
  },
  watch: {
    token(newValue){
      if(newValue){
        console.log('value_changed')
        this.reloadPage()
      }
    }
  },
  methods: {
    reloadPage(){
      this.$router.go(0);
    },
    logout(){
      localStorage.removeItem('token')
      this.$router.push({ name: 'home' })
      this.$store.state.token = null;
      axios.post('logout').then(() => {

      }).catch(response =>{
        console.log(response)
      })
    },
    getuserinfo(){
      this.$store.dispatch('info').then(response => {
        this.userinfo = response.data;
      })
    },

    menuchange(drawer) {
      bus.$emit('menuchange', drawer);

    },

  },
  mounted(){
    this.getuserinfo()
  }
}
</script>
<style>
.Btn-logout{
  float:right;
}
.header{
  background-color: lightgray;
}
.profile{
  float:right;

}

</style>
