<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog" width="auto">
      <v-card width="500" class="black--text" color="white">
        <v-card-title>
          Overzicht vernieuwen
        </v-card-title>
        <v-card-subtitle class="black--text">
          Kies de mogelijkheden die je wilt bijwerken.
        </v-card-subtitle>
        <v-card-text class="pa-2">
<!--          <div class="light-green">-->
<!--            <v-checkbox color="black" v-model="product" class="black-checkbox"></v-checkbox>-->
<!--            <p class="black&#45;&#45;text text-subtitle-1 checkbox_text">Producten</p>-->
<!--          </div>-->
<!--          <div class="light-green">-->
<!--            <v-checkbox disabled color="black" v-model="users" class="black-checkbox"></v-checkbox>-->
<!--            <p class="black&#45;&#45;text text-subtitle-1 checkbox_text">Klanten</p>-->
<!--          </div>-->
<!--          <div class="light-green">-->
<!--            <v-checkbox disabled color="black" v-model="orders" class="black-checkbox"></v-checkbox>-->
<!--            <p class="black&#45;&#45;text text-subtitle-1 checkbox_text">Bestellingen</p>-->
<!--          </div>-->
         <v-row>
           <v-col cols="12" sm="4" justify="center" align="center">
             <div @click="orders = !orders" class="div_icon_header_main" style="width: 100px; height: 100px; background-color: white; position: relative">
               <v-icon v-if="!orders" color="red" class="icon_header_main" size="60">mdi-truck-delivery-outline</v-icon>
               <v-icon v-else color="green" class="icon_header_main" size="60">mdi-truck-delivery-outline</v-icon>
             </div>
             <p class="mt-1">Bestellingen</p>
           </v-col>
           <v-col cols="12" sm="4"  justify="center" align="center">
             <div @click="users = !users" class="div_icon_header_main" style="width: 100px; height: 100px; background-color: white; position: relative">
               <v-icon v-if="!users" color="red" class="icon_header_main" size="60">mdi-account-group</v-icon>
               <v-icon v-else color="green" class="icon_header_main" size="60">mdi-account-group</v-icon>
             </div>
             <p class="mt-1">Klanten</p>
           </v-col>
           <v-col cols="12" sm="4" justify="center" align="center">
             <div @click="product = !product" class="div_icon_header_main" style="width: 100px; height: 100px; background-color: white; position: relative">
               <v-icon v-if="!product" color="red"  class="icon_header_main" size="60">mdi-desk</v-icon>
               <v-icon v-else color="green"  class="icon_header_main" size="60">mdi-desk</v-icon>
             </div>
             <p class="mt-1">Producten</p>
           </v-col>
         </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog" text color="red">Annuleren</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="sendRequest" text color="primary">Versturen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Refresh",
  data(){
    return{
      dialog: true,
      product: false,
      orders: false,
      users: false,
    }
  },
  methods: {
    closeDialog(){
      this.$emit('close-dialog')
    },
    sendRequest(){
      this.$store.dispatch('cache_products')
    }
  }
}
</script>

<style scoped>
.div_icon_header_main, v-btn{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
}
.icon_header_main{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
}
</style>