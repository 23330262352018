var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.selected)?_c('div',[_c('EditSingleProduct',{attrs:{"parent":_vm.parent.id,"selected":_vm.selected_id},on:{"close-overlay":_vm.removeOverlay}})],1):(_vm.batch_edit)?_c('div',[_c('Update',{attrs:{"children":_vm.children,"parent":_vm.parent.id,"filtered":_vm.filtered_rows},on:{"close-update":function($event){_vm.batch_edit = false},"cache-clear":_vm.refreshProducts,"filter-chips":function($event){_vm.chips = []}}}),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.batch_edit = false}}},[_vm._v("Sluiten")])],1):_c('div',[(_vm.loading)?_c('span',{staticClass:"d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1):_c('span',[_c('h1',[_vm._v(_vm._s(_vm.parent.name))]),(!_vm.filter)?_c('vue-good-table',{staticClass:"mr-16",attrs:{"styleClass":"vgt-table condensed","columns":_vm.columns,"theme":"my-theme","pagination-options":{
          enabled: true,
          perPage: 5,
          position: 'top',
          perPageDropdown: [5],
          dropdownAllowAll: true,
          firstLabel : 'Eerste pagina',
          lastLabel : 'Laatste pagina',
          nextLabel: 'Volgende',
          prevLabel: 'Vorige',
          rowsPerPageLabel: 'Regels per pagina',
          allLabel: 'All',
        },"search-options":{
          enabled: true
        },"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true
        },"fixed-header":true,"line-numbers":false,"rows":_vm.children},on:{"on-row-dblclick":_vm.onRowClick}},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',[_vm._v("Bewerken als groep")])]),_c('div',{attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"red","text":""},on:{"click":function($event){return _vm.$emit('close-overlay')}}},[_vm._v("Sluiten")]),_c('v-btn',{staticClass:"float-right",attrs:{"color":"indigo","text":""},on:{"click":_vm.filterProduct}},[_vm._v("Filteren")])],1)]):_vm._e(),(_vm.filter)?_c('div',[_vm._l((_vm.chips),function(item,index){return _c('div',{key:index,staticClass:"d-inline"},[_c('v-chip',{staticClass:"ma-2 d-inline",attrs:{"closable":""},on:{"click":function($event){return _vm.removeChip(index)}}},[_vm._v(" "+_vm._s(item.field + ': ' + item.value)),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-close")])],1)],1)}),_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_vm._l((_vm.allFilter),function(values,tabName){return _c('v-tab',{key:tabName},[_vm._v(_vm._s(tabName))])}),_vm._l((_vm.allFilter),function(values,tabName){return _c('v-tab-item',{key:tabName},[_c('v-list',_vm._l((values),function(value,index){return _c('div',{key:index},[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){return _vm.addProp(tabName, value)}}},[_vm._v(" "+_vm._s(value)+" ")])],1)}),0)],1)})],2)],2):_vm._e(),(_vm.filter)?_c('vue-good-table',{staticClass:"mr-16",attrs:{"styleClass":"vgt-table condensed","columns":_vm.columns,"theme":"my-theme","pagination-options":{
          enabled: true,
          perPage: 5,
          position: 'top',
          perPageDropdown: [5],
          dropdownAllowAll: true,
          firstLabel : 'Eerste pagina',
          lastLabel : 'Laatste pagina',
          nextLabel: 'Volgende',
          prevLabel: 'Vorige',
          rowsPerPageLabel: 'Regels per pagina',
          allLabel: 'All',
        },"search-options":{
          enabled: true
        },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true
        },"fixed-header":true,"line-numbers":false,"rows":_vm.filtered_products},on:{"on-selected-rows-change":_vm.batchEdit,"on-row-dblclick":_vm.onRowClick}},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',{on:{"click":_vm.switchBatchUpdate}},[_vm._v("Aanpassen als groep")])])]):_vm._e(),(_vm.filter)?_c('v-btn',{on:{"click":function($event){_vm.filter = !_vm.filter}}},[_vm._v("Sluiten")]):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }