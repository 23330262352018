<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4" class="pa-12">
        <v-card
            color="teal"
            theme="dark"
        >
          <v-card-title class="text-h6 white--text">
            Bestellingen
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="pa-12">
        <v-card
            color="indigo"
            theme="dark"
        >
          <v-card-title class="text-h6 white--text">
            Klanten
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" class="pa-12">
        <v-card
            color="orange"
            theme="dark"
        >
          <v-card-title class="text-h6 white--text">
            Producten
          </v-card-title>
          <v-spacer></v-spacer>

        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Summary"
}
</script>

<style scoped>

</style>