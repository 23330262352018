<template>
  <v-app style="background-color: rgb(239,241,243)">
  <product-header></product-header>
  <Summary></Summary>
  <table-list></table-list>
  </v-app>
</template>

<script>
import ProductHeader from "@/components/products/Header.vue";
import Summary from "@/components/products/Summary.vue";
import TableList from "@/components/products/TableList.vue";
export default {
  name: 'Home',
  components: {
    ProductHeader,
    TableList,
    Summary,
  }

}
</script>
