<template>
  <v-app>
    <v-row>
      <v-col v-if="!overlay" class="ms-8" cols="12">
        <vue-good-table
            style="width: 95%"
            :numbered="false"
            styleClass="vgt-table condensed"
                        :columns="columns"
                        :pagination-options="{
            enabled: true,
            perPage: 7,
            position: 'top',
            perPageDropdown: [5, 7, 10],
            dropdownAllowAll: true,
            firstLabel : 'Eerste pagina',
            lastLabel : 'Laatste pagina',
            nextLabel: 'Volgende',
            prevLabel: 'Vorige',
            rowsPerPageLabel: 'Regels per pagina',
            allLabel: 'All',
          }"
                        :search-options="{
            enabled: true
          }"
                        :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true
          }"

                        :fixed-header="true"
                        :line-numbers="false"
                        :rows="products" @on-row-dblclick="onRowClick">
          <div slot="selected-row-actions">
            <button>Aanpassen als groep</button>
          </div>
        </vue-good-table>
      </v-col>
      <v-col v-if="overlay" class="ms-8" cols="12">
        <children @close-overlay="removeOverlay" :parent="selected" :SHOW="overlay"></children>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import Children from "@/components/products/overlay/Children.vue";
import data from '@/assets/data.json'
import axios from "axios";
axios.defaults.baseURL = 'https://api.sapiasholding.nl/api/'
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
export default {
  name: "TableList.vue",
  components: {VueGoodTable, Children},
  data() {
    return{
      amount_of_pages: 0,
      products: [],
      overlay: false,
      selected: [],
      columns: [
        { field: "id", key: "id", label: "ID", align: "left" },
        {
          label: 'Afbeelding',
          field: 'image',
          html: true
        },
        { field: "name", key: "name", label: "Parent", align: "left" },
        { field: "type", key: "type", label: "Soort", align: "left" },
        { field: "sku", key: "sku", label: "SKU", align: "left" },
        { field: "amount_variations", key: "amount_variations", label: "Variaties", align: "left" },
      ],
    }
  },
  methods: {
    onRowClick(params){
      this.overlay = true;
      document.body.classList.add('no-scroll');
      this.selected = params.row;
      console.log(params.row)
    },
    removeOverlay(){
      this.overlay = false;
      document.body.classList.remove('no-scroll');
    }
  },
  mounted() {
    this.products = data.map(items => {
      let img = '';
      items.image.map(item => {
        if(item){
          img = '<img src='+item.src+' height="50" width="50">'
        } else {
          img = '<p>Geen afbeelding</p>'
        }
      })
      return {
        id: items.id,
        name: items.name,
        type: items.type,
        sku: items.sku,
        image: img,
        amount_variations: items.amount_variations
      }
    })
    console.log(this.products)
  },
  watch:{
    page(newVal){
      if(newVal){
        this.getProducts()
      }
    }
  }
}
</script>

<style>
.no-scroll {
  overflow: hidden;
}
</style>