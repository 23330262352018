<template>
    <div class="container">
      <h1>Register</h1>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="username">First Name:</label>
          <input type="text" id="username" v-model="username" required>
        </div>
        <div class="form-group">
          <label for="userlastname">Last Name:</label>
          <input type="text" id="userlastname" v-model="userlastname" required>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div class="form-group">
          <label for="PhoneNumber">Telefoonnummer:</label>
          <input type="number" id="Phonenumber" v-model="Phonenumber">
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password:</label>
          <input type="password" id="confirmPassword" v-model="confirmPassword" required>
        </div>
        <button class="v-btn" @click = "Registerfuct();" >Register</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "@/axios-config";
  export default {
    name: "RegistrationView",
    data() {
      return {
        username: "",
        userlastname: "",
        email: "",
        password: "",
        confirmpassword: "",
        phonenumber:"",
      };
    },
    methods: {
      Registerfuct: function ( ) {
         // Handle registration logic here
        axios.post('/coworkerregistration', {
      username: this.username,
      userlastname: this.userlastname,
      email: this.email, 
      password: this.password,
      confirmpassword: this.confirmPassword,
      phonenumber: this.Phonenumber, 
         }).then(response => {
        console.log(response);
        location.reload()
      })
       
    },
      }
  };
  </script>
  
  <style>
  .container {
    border: 1px solid black ;
    max-width: 100%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  
  }
  
  h1 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  </style>