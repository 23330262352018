<template>
    <v-container fluid>
      <v-layout column>
        <v-form>
            <v-container>
        <div>

            <h1>Company information {{ companydetails.companyname }}</h1>
            <p> Country:{{companydetails.country}}</p>
            <p> Postcode:{{companydetails.postcode}}</p>
            <p> Adress:{{companydetails.adress}}</p>
            <p> Btw number:{{companydetails.btw}}</p>
            <p> Kamer van koophandel number:{{companydetails.kvk}}</p>

            <v-btn class="ma-1" color="green"><router-link to="/updatecompany">update information</router-link></v-btn>
        </div>
      </v-container>
      </v-form>
          <div v-if="userinfo.verified === 1">
      <div v-if="companydetails.woocommercekey == null " class ="margintop">
            <v-form>
            <v-container>  
            <P>Your woocommerce is not linked yet, please add your key and secret key</P>
            <v-text-field
              v-model="woocommerce"
              label="woocommerce"
              required
              
            ><br></v-text-field>

            <v-text-field
              v-model="secretkey"
              label="secretkey"
              required
            ></v-text-field>
            <v-btn class="ma-1" color="green" @click="Addwoocommerce">Add woocommerce information</v-btn>         
            </v-container>
          </v-form>
      </div>
      <div v-else class ="margintop">
        <v-card>
        <v-card-text>
        <h1>Woocommerce</h1>
          <p>Key:{{companydetails.woocommercekey}}</p>
          <p>Secretkey:{{companydetails.woocommercesecretkey}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn class="ma-1" color="Red" @click="Deletewoocommerce">Delete</v-btn>
        </v-card-actions>
      </v-card>
      </div>
      <div v-if="companydetails.webstorelink == null " class ="margintop">
            <v-form>
            <v-container>  
            <P>Your Webshop is not linked yet, please add your link</P>
            <v-text-field
              v-model="webstorelink"
              label="webshop"
              required
              ></v-text-field>
            <v-btn class="ma-1" color="green" @click="Addwebstorelink">Add link</v-btn>
            </v-container>
            
          </v-form>
      </div>
          <div v-else class ="margintop">
           <v-card>
                <v-card-text>
                <h1>Webshop</h1>
                <p>Link {{companydetails.webstorelink}}</p>
                </v-card-text>
            <v-card-actions>
             <v-btn class="ma-1" color="Red" @click="Deletewebstorelink">Delete</v-btn>
            </v-card-actions>
           </v-card>
          </div><!--woocommerce check-->
          </div><!--verification check-->
          <div v-else>
           <P>Your account is not verified, please click the button below to send an e mail for verification</P>
           <v-btn class="ma-1" color="Blue" @click="verification">verify</v-btn>
           </div>
        <div class ="margintop">
          <h1>Your company users</h1>
          <v-card  v-for="username in companyinfo" :key="username.id">
          <p v-if="userinfo.id === username.id">

          </p>

          <v-card-text v-else>
            <v-flex class="mb-4">
            </v-flex>
            <h1>Personal account</h1>
            <li> First name: {{ username.username }} </li>
            <li> Last name: {{ username.userlastname }}</li>
            <li> Email: {{ username.email }} </li>
            <li v-if="username.accountrank == 1 ">Account rank: Gast</li>
            <li v-if="username.accountrank == 2 ">Account rank: Inkijken</li>
            <li v-if="username.accountrank == 3 ">Account rank: bewerker</li>
            <li v-if="username.accountrank == 4 ">Account rank: Coeigenaar</li>
            <hr><br> 
            
            <v-btn @click=deleteh(username.id) color="red" style="color: black" class="ma-1">
            delete account
          </v-btn> 
          <div class="dropdown">
            <v-btn class="dropbtn ma-1" color="green" style="color: black">Account rank change</v-btn>

            <div class="dropdown-content">
              <label>
            <input type="button" id="coeigenaar" v-model="coeigenaar" @click=accountrank(coeigenaar)>CO-eigenaar</label>
            <label>
            <input type="button" id="Bewerken" v-model="Bewerken" @click=accountrank(Bewerken)>Bewerken</label>
            <label>
            <input type="button" id="Inkijken" v-model="Inkijken" @click=accountrank(Inkijken)>Inkijken</label>
            </div>

            </div>
            <v-btn @click=confirm(username.id) color="green" style="color: black" class="ma-1">
            confirm change
          </v-btn> 
          <v-btn @click=transferownership(username.id) color="blue" style="color: black" class="ma-1">
            transfer ownership
          </v-btn> 
          </v-card-text>
          <v-card-actions>
  
 
          </v-card-actions>
        </v-card>
        <v-spacer></v-spacer> 
        <v-btn class="ma-1" color="green"> <router-link to="/Coworker">add coworker</router-link>
          </v-btn>
    </div>
    </v-layout>
    </v-container>
    </template>
  <script>
  import footerView from "@/components/footerView";
  import axios from "@/axios-config";
  export default {

  name: "DropdownButton",

    // eslint-disable-next-line vue/no-unused-components  
    components:{footerView},
    pageTitle: 'My Profile',
    data() {
      return {
        token: this.$store.state.token,
        userinfo: [
        ],
        username: "",
        loading: false,
        companyinfo: [],
        companydetails:[],
        coeigenaar:"4",
        Bewerken:"3",
        Inkijken:"2",
        rank:"",
        companypass:"",
        secretkey: '',
        woocommerce: '',
        webstorelink: '',

      }
    },
    methods:{

      getuserinfo(){
        this.$store.dispatch('info').then(response => {
          this.userinfo = response.data;
        })
      },
      getcompanyinfo(){ 
        axios.post('companyinfo').then(response => {
          this.companyinfo = response.data;
        })
        axios.post('companydetails').then(response => {
          this.companydetails = response.data;
        })
      },
      deleteh (idf) {
        this.check = prompt("Wil je dit account echt verwijderen? y/n");
      if(this.check === "y"){
      axios.post('/account/delete', {
      id: idf,
         }).then(response => { this.test = response.data;  location.reload(); alert("account deleted");
      })
    }
    else{
      alert("Kon account niet verwijderen");
    }

    },
    accountrank(accountrank){
      this.rank = accountrank
    },
    confirm(accountid){

      axios.post('rankupdate', {
      accountid: accountid,
      rank: this.rank,
         }).then(response => { this.test = response.data;  location.reload()
      })
    },
    transferownership(accountid){

      this.companypass = prompt("wat is uw bedrijf wachtwoord?");
      this.check = prompt("Wil je dit account echt Eigenaar maken? y/n");
      if(this.check === "y"){
      axios.post('transferownership', {
      accountid: accountid,
      companypass: this.companypass,
         }).then(response => { this.test = response.data;
      })
    }
    else{
      alert("Kon account rank niet updaten");
    }
    },
    Addwoocommerce(){
      axios.post('companyaddwoocommerce', {
        new_woocommerce: this.woocommerce,
        new_secretkey: this.secretkey,
         }).then(response => {
        console.log(response);
        location.reload()
      })
    },
    Deletewoocommerce(){
      this.check = prompt("Wil je deze keys echt verwijderen? y/n");
      if(this.check === "y"){
      axios.post('companydeletewoocommerce', {
         }).then(response => {
        console.log(response);
        location.reload()
      })

    }
    else{
      alert("Kon informatie niet verwijderen");
    }

    },
    Addwebstorelink(){
      axios.post('companyaddwebstore', {
        new_webstore: this.webstorelink,
         }).then(response => {
        console.log(response);
        location.reload()
      })
    },
    Deletewebstorelink(){
      this.check = prompt("Wil je deze keys echt verwijderen? y/n");
      if(this.check === "y"){
      axios.post('companydeletewebstore', {
         }).then(response => {
        console.log(response);
        location.reload()
      })
    }
    else{
      alert("Kon informatie niet verwijderen");
    }
    },
    verification(){
      axios.post('mail_verification_email', {
         }).then(response => {
        console.log(response);
        alert("E-mail verstuurd");
      })
    },

    },
    mounted(){
      this.getuserinfo()
      this.getcompanyinfo();
    },

  }
  </script>
 <style>
/* Dropdown Button */
.dropbtn {
  background-color: #04AA6D;
  color: black;
  padding: 5px;
  font-size: 16px;
  border: 1 px solid black;
  border-radius: 5px;
  margin-left: 5%
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #3e8e41;}

.margintop{
  margin-top: 15px;
  padding-top:15px;
}
</style>