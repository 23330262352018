import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductView from '../views/ProductView.vue'
import ProfileView from '../views/ProfileView.vue'
import ContactView from '../views/ContactView.vue'
import LoginView from "@/views/LoginView.vue";
import RegistrationView from "@/views/RegistrationView.vue";
import footerView from "@/components/footerView";
import ProductTable from "@/components/products/ProductTable.vue";
import updateView from "@/views/UpdateView.vue";
import CoworkerView from "@/views/CoworkerView";
import registrationCoworkerView from "@/views/RegistrationCoworkerView";
import OverviewCoworkersView from "@/views/OverviewCoworkersView";
import changepasswordView from "@/views/changepasswordView";
import forgotEmailView from "@/views/forgotEmailView";
import verifyView from "@/views/verifyView";
import updatecompany from "@/views/updatecompanyView";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/registrationcoworker',
    name: 'registrationcoworker',
    component: registrationCoworkerView
  },
  {
    path: '/overviewcoworkers',
    name: 'overviewcoworkers',
    component: OverviewCoworkersView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/product',
    name: 'product',
    component: ProductView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/footer',
    name: 'footer',
    component: footerView
  },
  {
    path: '/table',
    name: 'Table',
    component: ProductTable
  },
  {
    path: '/update',
    name: 'update',
    component: updateView
  },
  {
    path: '/coworker',
    name: 'coworker',
    component: CoworkerView
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: changepasswordView
  },
  {
    path: '/forgotEmail',
    name: 'forgotEmail',
    component: forgotEmailView
  },
  {
    path: '/verify',
    name: 'verify',
    component: verifyView
  },
  {
  path: '/updatecompany',
  name: 'updatecompany',
  component: updatecompany
  },





]

const router = new VueRouter({
  routes
})

export default router
