<template>
  <div class="background">
    <div class="d-flex align-center justify-center pa-4">
      <v-stepper vertical color="red" v-model="e6">
<!--        STEP 1-->
        <v-stepper-step :complete="e6 > 1" step="1">
          <div class="ml-5">
            <p>Welkom!</p>
<!--            <small>Vul hier je gegevens in</small>-->
          </div>
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-img width="600" src="@/assets/shop.png"></v-img>
          <v-btn class="float-end"
              color="primary"
              @click="e6 = 2"
          >
            Verder
          </v-btn>
          <v-btn class="float-end mb-1" text>
            Annuleren
          </v-btn>
        </v-stepper-content>
<!--        STEP 2-->
        <v-stepper-step :complete="e6 > 2" step="2">
          <div class="ml-5">
            <p>Persoonlijkegegevens</p>
                        <small>Vul hier je gegevens in</small>
          </div>
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-alert v-if="error_display"
                   class="animate__animated animate__fadeInUp"
                   type="error"
                   text
          >
            <p class="pl-4">{{error_message}}</p>
          </v-alert>
          <v-card flat width="600">
            <v-form ref="personal">
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                      label="Voornaam"
                      solo
                      dense
                      clearable
                      class="login-field mb-1"
                      type="text"
                      v-model="username"
                      :rules="nameRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      solo
                      clearable
                      dense
                      label="Achternaam"
                      class="login-field mb-1"
                      type="text"
                      v-model="userlastname"
                      :rules="surnameRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                      solo
                      clearable
                      dense
                      label="Adres"
                      class="login-field mb-1"
                      type="text"
                      v-model="adress"
                      :rules="streetRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      solo
                      clearable
                      dense
                      label="Postcode"
                      class="login-field mb-1"
                      type="text"
                      v-model="postcode"
                      :rules="zipRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      solo
                      clearable
                      dense
                      label="Stad"
                      class="login-field mb-1"
                      type="text"
                      :rules="cityRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <VuePhoneNumberInput :translations="{
  countrySelectorLabel: 'Land',
  countrySelectorError: 'Selecteer',
  phoneNumberLabel: 'Nummer',
  example: 'Voorbeeld :'
}" default-country-code="NL" :only-countries="['NL']" v-model="phonenumber" class="mb-2 nummer"></VuePhoneNumberInput>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      clearable
                      dense
                      solo
                      label="Land"
                      class="login-field mb-1"
                      type="text"
                      disabled
                      v-model="country"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-text-field
                    clearable
                    dense
                    solo
                    label="E-mail"
                    class="login-field mb-1 ml-1"
                    type="text"
                    v-model="email"
                    :rules="emailRules"
                ></v-text-field>
              </v-row>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                      clearable
                      dense
                      solo
                      label="Wachtwoord"
                      class="login-field mb-1"
                      type="password"
                      v-model="password"
                      :rules="passwordRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      clearable
                      dense
                      solo
                      label="Wachtwoord bevestigen"
                      class="login-field mb-1"
                      type="password"
                      v-model="passwordconfirm"
                      :rules="passwordConfirmRules"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-btn class="float-end"
                 color="primary"
                 @click="checkPersonal"
          >
            Verder
          </v-btn>
          <v-btn @click="e6 = e6 - 1" class="float-end mb-1" text>
            Terug
          </v-btn>
        </v-stepper-content>
<!--        STAP 3-->
        <v-stepper-step :complete="e6 > 3" step="3">
          <div class="ml-5">
            <p>Bedrijfsgegevens</p>
<!--            <small>Zoek naar KVK-nummer</small>-->
          </div>
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-card flat width="600">
            <v-card-subtitle>
              <p>Om uw bedrijf te valideren hebben we uw volgende gegevens nodig.</p>
            </v-card-subtitle>
            <v-card-text>
              <v-form ref="company">
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                      solo
                      clearable
                      :disabled="kvkcheck"
                      dense
                      label="Bedrijfsnaam"
                      class="login-field mb-1"
                      type="text"
                      v-model="companyname"
                      :rules="companynameRules"
                  ></v-text-field>
                </v-col>
                </v-row>
              <v-row dense>
                <v-col cols="6">
                  <v-text-field
                      solo
                      clearable
                      :disabled="kvkcheck"
                      dense
                      label="KVK nummer (Niet verplicht)"
                      class="login-field mb-1"
                      type="text"
                      v-model="kvk"
                      :rules="kvkRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      solo
                      clearable
                      :disabled="kvkcheck"
                      dense
                      label="EU BTW (Niet verplicht)"
                      class="login-field mb-1"
                      type="text"
                      v-model="btw"
                      :rules="btwRules"
                  ></v-text-field>

                </v-col>
              </v-row>
              </v-form>
            </v-card-text>
          </v-card>
          <v-btn class="float-end"
                 color="primary"
                 @click="checkCompany"
          >
            Voltooien!
          </v-btn>
          <v-btn @click="e6 = e6 - 1" class="float-end mb-1" text>
            Terug
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </div>
  </div>
</template>

<script>

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'Registration',
  components: {VuePhoneNumberInput},
  data(){
    return{
      e6: 1,
      // Regels
      nameRules: [
        (v) => !!v || 'Voornaam is verplicht',
        (v) => /^[a-zA-Z]{2,}$/.test(v) || 'Voornaam moet uit minimaal 2 letters bestaan'
      ],
      surnameRules: [
        (v) => !!v || 'Achternaam is verplicht',
        (v) => /^[a-zA-Z]{2,}$/.test(v) || 'Achternaam moet uit minimaal 2 letters bestaan'
      ],
      streetRules: [
        (v) => !!v || 'Adres is verplicht',
        (v) => /^[a-zA-Z\s]+\d{1,4}$/.test(v) || 'Vul een geldig Nederlands adres in'
      ],
      zipRules: [
        (v) => !!v || 'Postcode is verplicht',
        (v) => /^[1-9]\d{3}\s?[a-zA-Z]{2}$/.test(v) || 'Vul een geldige Nederlandse postcode in'
      ],
      cityRules: [
        (v) => !!v || 'Stad is verplicht',
        (v) => /^[a-zA-Z]{2,}$/.test(v) || 'Stad moet uit minimaal 2 letters bestaan'
      ],
      emailRules: [
        v => !!v || "E-mail is verplicht",
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "Voer een geldig e-mailadres in"
      ],
      passwordRules: [
        v => !!v || "Wachtwoord is verplicht",
        v => v.length >= 8 || "Wachtwoord moet ten minste 8 tekens bevatten"
      ],
      passwordConfirmRules: [
        v => !!v || "Wachtwoord bevestigen is verplicht",
        v => v === this.password || "Wachtwoorden moeten overeenkomen"
      ],
      companynameRules: [
        (v) => !!v || 'Bedrijfsnaam is verplicht',
        (v) => /^[a-zA-Z0-9 ]{2,}$/.test(v) || 'Bedrijfsnaam moet uit minimaal 2 letters bestaan'
      ],
      kvkRules: [
        (v) => v === null || v === '' || /^\d{8}$/.test(v) || 'Dit is geen geldig EU Vat Nummer. Voorbeeld: 12345678'
      ],
      btwRules: [
        (v) => v === null || v === '' || /^NL\d{9}B\d{2}$/.test(v) || 'Dit is geen geldig EU BTW. Voorbeeld: NL002152183B01'
      ],
      username:'',
      userlastname:'',
      email: '',
      kvkcheck:false,
      password:'',
      passwordconfirm:'',
      phonenumber:'',
      adress: '',
      postcode: '',
      error_display: false,
      error_message: '',
      country: 'Nederland',
      kvk: '',
      kvkPattern: /^NL\d{9}B\d{2}$/,
      btw: '',
      companyname: '',
      companypassword: '12345678',
      companypasswordconfirm: '12345678',
    }
  },
  watch: {
    kvk(newVal) {
      if (this.kvkPattern.test(newVal)) {
        // this.checkVat();
      }
    },
  },
  methods:{
    // checkVat(){
    //   this.kvkcheck = true;
    //   axios.get('https://api.vatcomply.com/vat?vat_number='+this.kvk).then(response => {
    //     console.log(response)
    //     this.kvkcheck = false;
    //   })
    // },
    checkPersonal(){
      if(!this.$refs.personal.validate()){
        return true;
      }
      this.e6 = 3;
    },
    checkCompany(){
      if(!this.$refs.company.validate()){
        return true;
      }
      this.Registerfuct()
    },

    Registerfuct: function ( ) {
      this.$store.dispatch('register',[
      this.username,
      this.userlastname,
      this.email, 
      this.password,
      this.passwordconfirm,
      this.phonenumber, 
      this.adress,
      this.postcode,
      this.country,
      this.kvk,
      this.btw,
      this.companyname,
      this.companypassword,
      this.companypasswordconfirm,
    ])
          .then(response => {
            console.log(response)
          }).catch(response => {
            console.log(response.response.data.message)
            if(response.response.data.message === 'The email has already been taken.'){
              this.error_display = true;
              this.error_message = 'De e-mail bestaat al in het systeem.'
              this.e6 = 2;
            } else {
              this.error_display = true;
              this.error_message = 'Er is iets misgegaan!'
            }
      })
    }

  },}
</script>
<style scoped>
.background{
  background-image: url("https://cdn.webshopapp.com/shops/20277/files/414323176/paint-paper-library-paint-paper-library-deep-water.jpg");
  object-fit: fill;
  height: 100%;
}
.nummer {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background: #FFFFFF;
  border: none;
  max-width: 100%;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
}
</style>