<template>
  <div class="background">
  <div class="d-flex align-center justify-center pa-4">
    <v-card width= "500">
<!--      Sucessfull Login-->
      <v-alert v-if="success"
          class="animate__animated animate__fadeInUp"
          type="success"
          text
      >
        <p class="pl-4 ellipsis">Je bent succesvol ingelogd en wordt doorverbonden</p>
      </v-alert>
<!--    Failed login-->
      <v-alert v-if="success === false && success !== true"
               class="animate__animated animate__fadeInUp"
               type="error"
               text
      >
        <p class="pl-4">Verkeerde gegevens. Probeer opnieuw!</p>
      </v-alert>
      <v-card-title class="d-flex align-center justify-center">
        <div>
          <v-img class="mx-auto" width="220" src="@/assets/logo.jpg"></v-img>
        </div>
      </v-card-title>
      <v-card-text class="pl-12">
        <h5 class="text-h5 font-weight-semibold mb-1 font-weight-bold">
          Welkom bij SapiasHolding! 👋🏻
        </h5>
        <p class="mb-0">
          Registreer of log in op je account en start het avontuur.
        </p>
      </v-card-text>
      <v-card-text class="pl-12 pr-12">
        <v-form ref="form" v-model="login" lazy-validation>
          <v-text-field
              outlined
              name="login"
              label="E-mail"
              class="login-field mb-1"
              type="text"
              v-model="email"
              :rules="emailRules"
          ></v-text-field>
          <v-text-field
              outlined
              v-model="password"
              name="password"
              label="Wachtwoord"
              class="login-field mb-1"
              type="password"
              :rules="passwordRules"
          ></v-text-field>
         <v-btn @click="Loginhostfuct" class="white--text" color="black" block>Inloggen</v-btn>
          <v-btn class="white--text mt-2" color="red" block>Wachtwoord vergeten?</v-btn>
        </v-form>
      </v-card-text>
      <div>
      <v-card-actions class="d-flex align-center justify-center grey lighten-4">
        <v-btn text block><router-link class="white--text d-block mx-5 teal--text text--darken-4" to="/registration">Nieuw account aanmaken</router-link></v-btn>
      </v-card-actions>
      </div>
    </v-card>
  </div>
  </div>
</template>

<script>


export default {
  name: 'Login',
    data: () => ({
      email:'',
      password:'',
      success: null,
      login: null,
      emailRules: [
        v => !!v || "E-mail is verplicht",
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "Voer een geldig e-mailadres in"
      ],
      passwordRules: [
        v => !!v || "Wachtwoord is verplicht",
        v => v.length >= 8 || "Wachtwoord moet ten minste 8 tekens bevatten"
      ]
    }),
  methods:{
    Loginhostfuct () {

      if(!this.$refs.form.validate()){
        return
      }
    this.$store.dispatch('login',[this.email, this.password])
        .then(() => {
          this.success = true;
          setInterval(() => {
            // this.$emit('changeNavbar');
            window.location.href ="/#product"
            location.reload()
          }, 3000);
        }).catch( () => {
          this.success = false;
    })
    },
  }



}
</script>
<style>
.background{
  background-image: url("https://cdn.webshopapp.com/shops/20277/files/414323176/paint-paper-library-paint-paper-library-deep-water.jpg");
  object-fit: fill;
  height: 100%;
}
.login-field input {
  border: none;
}
.ellipsis::after {
  content: '...';
  animation: ellipsis 2s infinite;
}

@keyframes ellipsis {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
}

</style>
