<template>
  <v-app class="mr-13">
     <span v-if="loading" class="mb-5 d-flex align-center justify-center">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
     </span>
    <v-btn @click="$emit('close-overlay')" color="red white--text">Sluiten</v-btn>
    <div>
      <v-tabs v-model="activeTab" align-with-title>
        <v-tab v-for="(item, index) in child" :key="index">{{ index }}</v-tab>
        <v-tab-item v-for="(item, index) in child" :key="index">
          <v-card class="mt-5">
            <template v-for="(value, key) in child[index]">
              <template v-if="typeof value !== 'object'">
                <div :key="key">
                  <p>{{ key }}</p>
                  <div v-if="key === 'src'">
                    <v-img height="250" width="250" :src="child[index][key]"></v-img>
                  </div>
                  <div v-if="key !== 'src' && key !== 'description' && key !== 'short_description' && child[index][key].length > 150">
                    <v-textarea solo outlined single-line dense :label="key" v-model="child[index][key]"></v-textarea>
                  </div>
                  <div v-if="key === 'description' || key === 'short_description'">
                    <vue-editor v-model="child[index][key]"></vue-editor>
                  </div>
                  <div v-if="key === 'id'">
                    <v-text-field disabled solo outlined single-line dense :label="key" v-model="child[index][key]"></v-text-field>
                  </div>
                  <div v-else>
                    <v-text-field solo outlined single-line dense :label="key" v-model="child[index][key]"></v-text-field>
                  </div>
                </div>
              </template>
              <template v-else>
                <div v-for="(subValue, subKey) in value" :key="subKey">
                  <template v-if="typeof subValue !== 'object'">
                    <div>
                      <div v-if="subKey === 'src'">
                        <v-img :src="key"></v-img>
                      </div>
                      <div v-if="key !== 'src' && child[index][key].length > 150">
                        <v-textarea solo outlined single-line dense :label="key" v-model="child[index][key]"></v-textarea>
                      </div>
                      <div v-if="subKey === 'id'">
                        <p class="mt-10"><b>Veld</b>: {{ key+1 }}</p>
                        <v-text-field disabled solo outlined single-line dense :label="subKey" v-model="child[index][key][subKey]"></v-text-field>
                      </div>
                      <div v-else>
                        <p>{{ subKey }}</p>
                        <v-text-field solo outlined single-line dense :label="subKey" v-model="child[index][key][subKey]"></v-text-field>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <template v-for="(innerValue, innerKey) in subValue">
                      <div :key="innerKey">
                        <div v-if="innerKey === 'id'">
                          <p class="mt-6"><b>Veld</b>: {{ key }}</p>
                          <v-text-field disabled solo outlined single-line dense :label="innerKey" v-model="child[index][key][subKey][innerKey]"></v-text-field>
                        </div>
                        <div v-if="innerKey === 'src'">
                          <v-img class="mb-12" contain :src="key"></v-img>
                        </div>
                        <div v-if="key !== 'src' && child[index][key].length > 150">
                          <v-textarea solo outlined single-line dense :label="key" v-model="child[index][key]"></v-textarea>
                        </div>
                        <div v-else>
                          <p>{{ innerKey }}</p>
                          <v-text-field solo outlined single-line dense :label="innerKey" v-model="child[index][key][subKey][innerKey]"></v-text-field>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </template>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-app>
</template>

<script>
import axios from "@/axios-config";
import { VueEditor } from "vue2-editor";
export default {
  name: "EditSingleProduct",
  props: ['parent', 'selected'],
  components: {VueEditor},
  data(){
    return{
      child: [],
      activeTab: null,
      loading: true,
    }
  },
  methods: {
    transform(json) {
      const parent = {};
      const child = {};

      for (const [key, value] of Object.entries(json)) {
        if (typeof value === 'object' && value !== null) {
          child[key] = value;
        } else {
          parent[key] = value;
        }
      }

      return Object.keys(child).length === 0 ? parent : {parent, ...child};
    }
  },
  mounted() {
    axios.post('single_variable_product', {
      parent: this.parent,
      child: this.selected
    }).then(response => {
      this.child = response.data;
      this.child = this.transform(response.data)
      this.loading = false;
      console.log(this.child)
    })
  }
}
</script>

<style>
</style>