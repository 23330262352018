const state = {
    data: ''
}

// const getters = {
//
// }

const mutations = {
    setData(state, payload) {
        state.data = payload
    }
}

const actions = {
    updateData({ commit }, payload) {
        commit('setData', payload)
    }
}

export default {
    state,
    mutations,
    actions,
    // getters
}