<template>
  <v-data-table :headers="headers" :footer-props="{'items-per-page-options':[10]}" :items="products" :loading="loading" :items-per-page="10" page="10">
    {{headers}}
  </v-data-table>
</template>

<script>
  import axios from "@/axios-config";
  export default {
    data() {
      return {
        headers: [
          { text: 'Naam', value: 'name' },
          { text: 'Gecreëerd', value: 'date_created' },
          { text: 'Prijs', value: 'price' },
          { text: 'Parent', value: 'parent' },
          { text: 'SKU', value: 'sku' }
        ],
        products: [],
        loading: true
      };
    },
    methods: {
      getProducts() {
        this.loading = true;
        axios.get('orders')
            .then(response => {
              this.products = response.data;
            })
            .finally(() => {
              this.loading = false;
            });
      },
      formatDate(date) {
        return new Date(date).toLocaleDateString();
      },
      formatPrice(price) {
        return '$' + price;
      }
    },
    mounted() {
      this.getProducts();
    }
  };
</script>

<style scoped>

</style>