<template>
  <v-form style="width: 95%; padding-left: 5%; padding-top: 3%"
      ref="form"
      v-model="valid"
      validation
  >
    <v-text-field
        v-model="name"
        :counter="20"
        :rules="nameRules"
        label="Name"
        required
    ></v-text-field>

    <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
    ></v-text-field>

    <v-text-field
        :counter="10"
        label="Phone Number (optional)"
    ></v-text-field>

    <v-select
        v-model="select"
        :items="items"
        :rules="[v => !!v || 'Item is required']"
        label="Wat voor vraag"
        required
    ></v-select>

    <v-text-field
        name="question"
        label="zet hier je vraag"
        :rules="[v => !!v || 'Question is required']"
        required
    ></v-text-field>

    <v-checkbox
        v-model="checkbox"
        :rules="[v => !!v || 'You must agree to continue!']"
        label="Valideer dat je geen robot bent"
        required
    ></v-checkbox>

    <v-btn
        :disabled="!valid"
        color="success"
        class="mr-4"
        @click="validate"
    >
      Validate
    </v-btn>

    <v-btn
        color="error"
        class="mr-4"
        @click="reset"
    >
      Reset Form
    </v-btn>

    <v-btn
        color="warning"
        @click="resetValidation"
    >
      Reset Validation
    </v-btn>
    <v-row style="height: 150px"></v-row>
  </v-form>
</template>
<script>

import footerView from "@/components/footerView";

export default {
  data: () => ({
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 20) || 'Name must be less than 20 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    select: null,
    items: [
      'Er is iets fout gegaan',
      'Profiel vraag',
      'Bug website',
      'Elders',
    ],
    checkbox: false,
  }),

  methods: {
    validate () {
      this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
  },
  name: 'Footer',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    footerView,
  }
}
</script>
