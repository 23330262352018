<template>
    <v-container fluid>
      <v-layout column>
        <v-card>
          <v-card-text>
            <v-flex class="mb-4">
            </v-flex>
            <h1> Change Data for company:{{companyinfo.companyname}} </h1><br>
            <div class="container">
              <form>
                <div class="form-group">
                  <label for="country">Land:</label>
                  <input type="text" id="country" v-model="country">
                </div>
                <div class="form-group">
                  <label for="postcode">Postcode:</label>
                  <input type="text" id="postcode" v-model="postcode">
                </div>
                <div class="form-group">
                  <label for="adress">Adres:</label>
                  <input type="text" id="adress" v-model="adress">
                </div>
                <div class="form-group">
                  <label for="btw">Btw number:</label>
                  <input type="text" id="btw" v-model="btw">
                </div>
                <div class="form-group">
                  <label for="kvk">Kamer van koophandel number:</label>
                  <input type="text" id="kvk" v-model="kvk">
                </div>
              </form>
            </div>
          </v-card-text>
          <v-card-actions>
  
            <v-spacer></v-spacer>
            <v-btn @click="Update_data">Update data</v-btn>
          </v-card-actions>
        </v-card>

      </v-layout>
    </v-container>
  </template>
  
  <script>
  import footerView from "@/components/footerView";
  import axios from "@/axios-config";
  export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {footerView,},
    pageTitle: 'Company Profile',
    data() {
      return {
        token: this.$store.state.token,
        userinfo: [],
        companyinfo:[],
        loading: false,
        confirm: false,
        country: '',
        postcode: '',
        adress: '',
        btw: '',
        kvk: '',
        
      }
    },
    methods: {

      Update_data: function () {
        this.check = prompt("Wil je dit echt veranderen y/n");
        if(this.check === "y"){
        axios.post('updatecompany', {
          country: this.country,
          postcode: this.postcode,
          adress: this.adress,
          btw: this.btw,
          kvk: this.kvk,
        }).then(response => {
          console.log(response);
          window.location.href = "/overviewcoworkers";
        })
        alert("Gegevens veranderd");
      }
      else{
        alert("Kon gegevens niet veranderen");
      }
  
      },
      getcompanyinfo(){ 
        axios.post('companyinfo').then(response => {
          this.companyinfo = response.data;
        })
      },
      
    },
    mounted() {
      this.getcompanyinfo();
    }
  }
  </script>