const state = {
    menu: "lol",
}

// const getters = {
//
// }

const mutations = {
    updateMenu(state, payload){
        state.menu = payload
        console.log(state.menu)
    }
}

const actions = {
    openMenu({commit}, payload){
        commit('updateMenu', payload)
    }
}

export default {
    state,
    mutations,
    actions,
    // getters
}