<template>
    <v-form>
      <v-container>
        <h1>Verander wachtwoord</h1>    
            <v-text-field
                v-model="user_email"
                label="Email"
                required
                
            ><br></v-text-field>
            <v-text-field
                v-model="user_code"
                label="code"
                required
                
            ><br></v-text-field>
  
            <v-text-field
                v-model="new_password"
                label="password"
                required
            ></v-text-field>
            <v-text-field
                v-model="new_confirm_password"
                label="password confirm"
                required
            ></v-text-field>
              </v-container>

      <v-btn class="float-right ma-3" @click = 'updatepassword'>verander wachtwoord</v-btn>
    </v-form>
  </template>
  
  <script>
  
  import axios from "axios";
  export default {
    name: 'changepassword',
      data: () => ({
        user_email:'',
        new_password:'',
        new_confirm_password:'',
        user_code:'',
      }),
    methods:{
      
      updatepassword: function () {
      axios.post('/changepassword', {
        new_password: this.new_password,
        new_confirm_password:  this.new_confirm_password,
        user_email: this.user_email, 
        user_code: this.user_code,
         }).then(response => {
        console.log(response);
        alert("wachtwoord veranderd");
        window.location.href = "/Login";
      })
    

    }
  }
}
  
  
  </script>