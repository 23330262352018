<template>
<div class="header">
  <v-row>
    <v-col cols="12" sm="1" class="pa-12">
      <div class="div_icon_header_main" style="width: 70px; height: 70px; background-color: white; position: relative">
        <v-icon class="icon_header_main" size="40">mdi-store</v-icon>
      </div>
    </v-col>
    <v-col cols="12" sm="5" class="pt-11 pl-8">
      <h1>Productbeheer</h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, quo?</p>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="12" sm="3" class="pa-15">
      <v-btn @click="showComponent = !showComponent" variant="flat" color="primary"><v-icon class="mr-2">mdi-refresh</v-icon>Overzicht vernieuwen</v-btn>
    </v-col>
    <div v-if="showComponent">
      <Refresh @close-dialog="showComponent = false"></Refresh>
    </div>
  </v-row>

</div>
</template>

<script>
import Refresh from "@/components/products/Refresh.vue";

export default {
  name: "Header",
  components: {Refresh},
  data(){
    return{
      showComponent: false,
    }
  }
}
</script>

<style scoped>
.header{
  background-color: rgb(248,250,251);
}
.div_icon_header_main, v-btn{
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
}
.icon_header_main{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
</style>