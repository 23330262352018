<template>
  <v-container fluid>
    <v-layout column>
      <v-card>
        <v-card-text>
          <v-flex class="mb-4">
          </v-flex>
          <h1> Change Email for user:{{userinfo.username}} </h1><br>
          <hr><br>
          <div class="form-group">
          <label for="new_email">new email:</label>
          <input type="email" id="new_email" v-model="new_email" required>
          </div>
          <div class="form-group">
          <label for="new_confirm_email">confirm new email:</label>
          <input type="email" id="new_email" v-model="new_confirm_email" required>
          </div>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn  @click='Update_email' >Update Email</v-btn>


      </v-card-actions>

      <v-card>
        <v-card-text>
          <v-flex class="mb-4">
          </v-flex>
          <h1> Change Password for user:{{userinfo.username}} </h1><br>
          <hr><br>
          <div class="form-group">
          <label for="old_password">old password:</label>
          <input type="password" id="old_password" v-model="old_password" required>
          </div>
          <div class="form-group">
          <label for="new_password">new password:</label>
          <input type="password" id="new_password" v-model="new_password" required>
          </div>
          <div class="form-group">
          <label for="new_confirm_password">confirm new password:</label>
          <input type="password" id="new_confirm_password" v-model="new_confirm_password" required>
          </div>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn  @click='Update_password' >Update Password</v-btn>


      </v-card-actions>

      <v-card   v-if="userinfo.accountrank === 5">
        <v-card-text>
          <v-flex class="mb-4">
          </v-flex>
          <h1> Change Data for user:{{userinfo.username}} </h1><br>
          <div class="container">
            <form>
              <div class="form-group">
                <label for="username">First Name:</label>
                <input type="text" id="username" v-model="username">
              </div>
              <div class="form-group">
                <label for="userlastname">Last Name:</label>
                <input type="text" id="userlastname" v-model="userlastname">
              </div>
              <div class="form-group">
                <label for="Phonenumber">Phonenumber:</label>
                <input type="number" id="Phonenumber" v-model="Phonenumber">
              </div>
            </form>
          </div>
        </v-card-text>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn @click="Update_data">Update data</v-btn>
        </v-card-actions>
      </v-card>
      <v-card   v-if="userinfo.accountrank <= 4">
        <v-card-text>
          <v-flex class="mb-4">
          </v-flex>
          <h1> Change Data for user:{{userinfo.username}} </h1><br>
          <div class="container">
            <form>
              <div class="form-group">
                <label for="username">First Name:</label>
                <input type="text" id="username" v-model="username">
              </div>
              <div class="form-group">
                <label for="userlastname">Last Name:</label>
                <input type="text" id="userlastname" v-model="userlastname">
              </div>
              <div class="form-group">
                <label for="Phonenumber">Telefoonnummer:</label>
                <input type="number" id="Phonenumber" v-model="Phonenumber">
              </div>
            </form>
          </div>
        </v-card-text>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn @click="Update_data">Update data</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import footerView from "@/components/footerView";
import axios from "@/axios-config";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {footerView,},
  pageTitle: 'My Profile',
  data() {
    return {
      token: this.$store.state.token,
      userinfo: [],
      loading: false,
      confirm: false,
      new_password: '',
      new_confirm_password: '',
      old_password: '',
      new_email: '',
      new_confirm_email: '',
      username: '',
      userlastname: '',
      Phonenumber: '',
      
    }
  },
  methods: {
    changepassword() {
    },
    getuserinfo() {
      this.$store.dispatch('info').then(response => {
        this.userinfo = response.data;
      })
    },
    Update_password: function () {
      this.check = prompt("Wil je dit echt veranderen y/n");
      if(this.check === "y"){
      axios.post('password/change', {
        new_password: this.new_password,
        new_confirm_password: this.new_confirm_password, old_password: this.old_password,}).then(response => {
        console.log(response);
        alert("wachtwoord veranderd");
        window.location.href = "/profile";
      })
    }
    else{
      alert("Kon wachtwoord niet veranderen");
    }

    },
    Update_data: function () {
      this.check = prompt("Wil je dit echt veranderen y/n");
      if(this.check === "y"){
      axios.post('data/change', {
        username: this.username,
        userlastname: this.userlastname,
        Phonenumber: this.Phonenumber,
        company: this.company,
      }).then(response => {
        console.log(response);
        window.location.href = "/profile";
      })
      alert("Gegevens veranderd");
    }
    else{
      alert("Kon gegevens niet veranderen");
    }

    },
    Update_email: function () {
      this.check = prompt("Wil je dit echt veranderen y/n");
      if(this.check === "y"){
      axios.post('email/change', {
        new_email: this.new_email,
        new_confirm_email: this.new_confirm_email, }).then(response => {
        console.log(response);
        window.location.href = "/profile";
      })
      alert("Gegevens veranderd");
    }
    else{
      alert("Kon gegevens niet veranderen");
    }

    },
  },
  mounted() {
    this.getuserinfo()
  }
}
</script>