var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-row',[(!_vm.overlay)?_c('v-col',{staticClass:"ms-8",attrs:{"cols":"12"}},[_c('vue-good-table',{staticStyle:{"width":"95%"},attrs:{"numbered":false,"styleClass":"vgt-table condensed","columns":_vm.columns,"pagination-options":{
          enabled: true,
          perPage: 7,
          position: 'top',
          perPageDropdown: [5, 7, 10],
          dropdownAllowAll: true,
          firstLabel : 'Eerste pagina',
          lastLabel : 'Laatste pagina',
          nextLabel: 'Volgende',
          prevLabel: 'Vorige',
          rowsPerPageLabel: 'Regels per pagina',
          allLabel: 'All',
        },"search-options":{
          enabled: true
        },"select-options":{
          enabled: false,
          selectOnCheckboxOnly: true
        },"fixed-header":true,"line-numbers":false,"rows":_vm.products},on:{"on-row-dblclick":_vm.onRowClick}},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('button',[_vm._v("Aanpassen als groep")])])])],1):_vm._e(),(_vm.overlay)?_c('v-col',{staticClass:"ms-8",attrs:{"cols":"12"}},[_c('children',{attrs:{"parent":_vm.selected,"SHOW":_vm.overlay},on:{"close-overlay":_vm.removeOverlay}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }