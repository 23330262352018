<template>
  <v-footer
      dark
      padless
  >
    <v-card
        class="flex"
        flat
        tile
    >
      <p class="footer-P">Maak kennis met Sapias Holding, de uitvinder van deze applicatie</p>
      <br>
      <div class="footer_content" v-if="this.$store.state.token">
        <div>
          <strong>link naar Sapias Holding</strong><br>
          <v-btn text ><router-link to="https://www.sapiasholding.nl/%22%3E"> Sapias Holding</router-link> </v-btn>
        </div>
        <div>
          <strong>Navigatie</strong><br>
          <div>
            <v-btn text><router-link to="/">Home</router-link></v-btn>
          <br>
          <v-btn text ><router-link   to="/product">Product info</router-link></v-btn>
          <br>
          <v-btn text ><router-link to="/contact">Contact</router-link></v-btn>
          <br>
          <v-btn text  ><router-link to="/profile">Profile</router-link></v-btn>
        </div>
      </div>

      </div>
      <div class="footer_content" v-else>
        <div>
          <strong>link naar Sapias Holding</strong><br>
          <v-btn text ><router-link to="https://www.sapiasholding.nl/%22%3E"> Sapias Holding</router-link> </v-btn>
        </div>
        <div>
          <strong>Navigatie</strong><br>
          <div>
            <v-btn text><router-link to="/login">Home</router-link></v-btn>
          <br>
          <v-btn text ><router-link   to="/login">Product info</router-link></v-btn>
          <br>
          <v-btn text ><router-link to="/login">Contact</router-link></v-btn>
          <br>
          <v-btn text  ><router-link to="/login">Profile</router-link></v-btn>
        </div>
      </div>
      <v-btn
          class="mx-4"
          dark
          icon
      >
      </v-btn>

      </div>

      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "footerView",
  data: () => ({}),
}
</script>
<style>
.footer_content {
  display: flex;
}

.footer_content > div {
  background-color: #f1f1f1;
  width: 50%;
  text-align: center;
  font-size: 15px;
}

.footer-P {
  background: grey;
  text-align: center;
}
strong{
  color: black;
}
</style>
