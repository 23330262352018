<template>
  <v-app>
    <div v-if="selected">
      <EditSingleProduct  @close-overlay="removeOverlay" :parent="parent.id" :selected="selected_id"/>
    </div>

    <!--Batch Edit view-->
    <div v-else-if="batch_edit">
      <Update @close-update="batch_edit = false" @cache-clear="refreshProducts" @filter-chips="chips = []" :children="children" :parent="parent.id" :filtered="filtered_rows">

      </Update>
      <v-btn text @click="batch_edit = false">Sluiten</v-btn>
    </div>
    <div v-else>
       <span v-if="loading" class="d-flex align-center justify-center">
          <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </span>

      <span v-else>
          <h1>{{parent.name}}</h1>
          <vue-good-table v-if="!filter" class="mr-16"
                          styleClass="vgt-table condensed"
                          :columns="columns"
                          theme="my-theme"
                          :pagination-options="{
            enabled: true,
            perPage: 5,
            position: 'top',
            perPageDropdown: [5],
            dropdownAllowAll: true,
            firstLabel : 'Eerste pagina',
            lastLabel : 'Laatste pagina',
            nextLabel: 'Volgende',
            prevLabel: 'Vorige',
            rowsPerPageLabel: 'Regels per pagina',
            allLabel: 'All',
          }"
                          :search-options="{
            enabled: true
          }"
                          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: true
          }"
                          :fixed-header="true"
                          :line-numbers="false"
                          :rows="children" @on-row-dblclick="onRowClick">

          <div slot="selected-row-actions">
            <button>Bewerken als groep</button>
          </div>
            <div slot="table-actions">
              <v-btn @click="$emit('close-overlay')" class="float-right" color="red" text>Sluiten</v-btn>
              <v-btn @click="filterProduct" class="float-right" color="indigo" text>Filteren</v-btn>
            </div>
        </vue-good-table>
        <div v-if="filter">
          <div class="d-inline" v-for="(item ,index) in chips" :key="index">
            <v-chip
                class="ma-2 d-inline"
                closable
                @click="removeChip(index)"
            >
              {{item.field + ': ' + item.value}}<v-icon small right>mdi-close</v-icon>
            </v-chip>
          </div>
           <v-tabs v-model="activeTab">
              <v-tab v-for="(values, tabName) in allFilter" :key="tabName">{{ tabName }}</v-tab>
              <v-tab-item v-for="(values, tabName) in allFilter" :key="tabName">
                <v-list>
                  <div v-for="(value, index) in values" :key="index">
                    <v-btn @click="addProp(tabName, value)" small text>
                      {{ value }}
                    </v-btn>
                  </div>
                </v-list>
              </v-tab-item>
            </v-tabs>
        </div>
        <vue-good-table v-if="filter" class="mr-16"
                        @on-selected-rows-change="batchEdit"
                        styleClass="vgt-table condensed"
                        :columns="columns"
                        theme="my-theme"
                        :pagination-options="{
            enabled: true,
            perPage: 5,
            position: 'top',
            perPageDropdown: [5],
            dropdownAllowAll: true,
            firstLabel : 'Eerste pagina',
            lastLabel : 'Laatste pagina',
            nextLabel: 'Volgende',
            prevLabel: 'Vorige',
            rowsPerPageLabel: 'Regels per pagina',
            allLabel: 'All',
          }"
                        :search-options="{
            enabled: true
          }"
                        :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true
          }"
                        :fixed-header="true"
                        :line-numbers="false"
                        :rows="filtered_products" @on-row-dblclick="onRowClick">

          <div slot="selected-row-actions">
            <button @click="switchBatchUpdate">Aanpassen als groep</button>
          </div>
        </vue-good-table>
         <v-btn v-if="filter" @click="filter = !filter">Sluiten</v-btn>
        </span>
    </div>
  </v-app>
</template>

<script>
import axios from "@/axios-config";
import "@/assets/style.scss";
import { VueGoodTable } from 'vue-good-table';
import EditSingleProduct from "@/components/products/children/EditSingleProduct.vue";
import Update from "@/components/products/batch/Update.vue";
export default {
  name: "Children",
  props: ['parent', 'SHOW'],
  components: {VueGoodTable, EditSingleProduct, Update},
  data(){
    return {
      loading: false,
      selected: false,
      filtered_products: [],
      filtered_rows: [],
      chips: [],
      filtered_chips: [],
      activeTab: null,
      selected_id: 1,
      columns: [
        { field: "id", key: "id", label: "ID", align: "left" },
        {
          label: 'Afbeelding',
          field: 'image',
          html: true,
        },
        { field: "attributes", html: true, key: "attributes", label: "Attributen", align: "left" },
        { field: "price", key: "price", label: "Prijs", align: "left" },
        { field: "sku", key: "sku", label: "SKU", align: "left" },
      ],
      children: [],
      allFilter: [],
      sorted: [],
      batch_edit: false,
      filter: false,
    }
  },
  methods: {
    closeOverlay(){
      this.$emit('close-overlay')
    },
    batchEdit(edit){
      this.filtered_rows = edit
      console.log(this.filtered_rows.selectedRows)
    },
    switchBatchUpdate(){
      this.batch_edit = true;
      console.log(this.batch_edit)
    },
    onRowClick(params){
      this.selected_id = params.row.id
      this.selected = true;
    },
    filterProduct(){
      this.filter = !this.filter
      axios.post('filter_product', {
        product: this.parent.id,
        variations: this.parent.amount_variations
      }).then(response => {
        this.allFilter = response.data
      })
    },
    addProp(tab, value){
      if(!this.chips.includes()){
        this.chips.push({field: tab, value: value})

        const uniqueChips = [];
        const duplicateFields = [];

        this.chips.forEach(chip => {
          if (!duplicateFields.includes(chip.field)) {
            uniqueChips.push(chip);
            duplicateFields.push(chip.field);
            this.chips = uniqueChips
            this.sortChips()
          }
        });
      }
    },
    sortChips(){
      this.filtered_products = []
      this.children.forEach((element) => {
        let cleanText = element.attributes.replace(/<\/?[^>]+(>|$)/g, "");
        let price = element.price;
        let matchCount = 0;
        this.chips.forEach((chip) => {
          if (chip['field'] === 'price') {
            if (price === chip['value']) {
              matchCount++;
            }
          } else {
            if (cleanText.includes(chip['field'] + ': ' + chip['value'])) {
              matchCount++;
            }
          }
        });
        if (matchCount === this.chips.length) {
          this.filtered_products.push(element);
        }
        this.filterChipsBySelection()
      })
    },
    filterChipsBySelection(){
      /*Filter customize*/
      this.filtered_products.forEach((element) => {
        /*Regex to perform*/
        const regex = /<span style="font-weight: bold;">([^<]*)<\/span><span>([^<]*)<\/span>/g;
        let match;
        /*Result array*/
        const result = {};

        /*Transform HTML elements to an array in JS*/
        while ((match = regex.exec(element.attributes)) !== null) {
          const key = match[1].trim().replace(':', '');
          const data = match[2];
          this.chips.forEach((element) => {
            if(key+':'+data === element.field+":"+element.value){
              result[key] = data;
            }
          })
        }
        /*Check each attribute*/
        for(let attributes in result){
          for(let chip in this.allFilter){
            // console.log(attributes + '+'+result[attributes])
            // console.log(chip + '+' + this.allFilter[chip])
            this.allFilter[chip].forEach((chipped) => {
              // console.log(chip + ':' + element)
              if(chip+':'+chipped === attributes+':'+result[attributes]){
                // console.log(result)
              }
            })
          }
        }
      })

    },
    removeChip(index){
      this.chips.splice(index, 1);
      this.sortChips()
    },
    removeOverlay(){
      this.selected = false;
    },
    refreshProducts(){
      this.batch_edit = false;
      this.loading = true;
      axios.post('get_product_full',{
        product: this.parent.id,
        variations: this.parent.amount_variations
      }).then(response => {
        this.children = response.data.map(item => {
          let sorted = '<span>';
          item.attributes.map(item => {
            sorted = sorted + '<span><span style="font-weight: bold;">'+item.name+': </span><span>'+item.option+'</span><br></span>'
            // console.log(sorted)
          })
          return {
            id: item.id,
            attributes: sorted,
            price: item.price,
            sku: item.sku,
            image: '<img src='+item.image+' height="50" width="50">',
          };
        })
        this.loading = false;
        // console.log(this.children)
      })
    }
  },
  mounted() {
    this.refreshProducts()
  }
}
</script>

<style scoped>
.my-overlay >>> .v-overlay__content {
  width: 100%;
  height: 100%;
}

</style>