<template>
  <v-container style="margin: 0; padding: 0;" fluid>
    <v-img contain src="@/assets/header_index.svg"></v-img>
    <v-row class="mt-10">
      <v-spacer></v-spacer>
      <v-col cols="12" md="5">
        <div class="text-block" style="max-width:80%;">
          <h2>Oplossingen voor Wordpress</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer commodo, nulla eget euismod ullamcorper, ante magna commodo dolor, id blandit quam ante id odio. Sed in blandit dolor, at ullamcorper metus. Aenean auctor, libero id facilisis malesuada, sapien diam egestas sem, vel aliquam velit velit vel sapien. Nam in laoreet massa, quis consectetur tellus. Suspendisse potenti. Proin fringilla neque nunc, ut gravida nisl rutrum a.</p>
          <v-spacer></v-spacer>
          <div class="mt-10">
            <v-btn @click="sendProp" color="amber lighten-1">Klik hier om meer te weten te komen</v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="5" class="hidden-xs-only">
        <v-img contain src="@/assets/programming_computer.png" alt="image"></v-img>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Home',
  components: {

  }

}
</script>

