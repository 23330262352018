<template>
      <div class="container">
      <h1>Co worker Register</h1>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="username">First Name:</label>
          <input type="text" id="username" v-model="username" required>
        </div>
        <div class="form-group">
          <label for="userlastname">Last Name:</label>
          <input type="text" id="userlastname" v-model="userlastname" required>
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div class="form-group">
          <label for="phoneNumber">Telefoonnummer:</label>
          <input type="number" id="phonenumber" v-model="phonenumber">
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <div class="form-group">
          <label for="passwordconfirm">Confirm Password:</label>
          <input type="password" id="passwordconfirm" v-model="passwordconfirm" required>
        </div>
        <div class="form-group">
          <label for="current"></label>
          <input type="hidden" id="current" v-model="current">
        </div>
        <v-btn v-b-tooltip.hover title="Send an e-mail to your co worker" @click =  'Registerfuct'> Register a Co worker</v-btn>  
      
      </form>
    </div>
</template>

<script>
import footerView from "@/components/footerView";
import axios from "@/axios-config";
export default {

  // eslint-disable-next-line vue/no-unused-components
  components:{footerView},
  pageTitle: 'Add coworker to Profile',

  data() {
    return {
      token: this.$store.state.token,
      userinfo: [
      ],
      loading: false,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  methods:{

    Registerfuct: function () {
      axios.post('/coworkerregistration', {
      username: this.username,
      userlastname:  this.userlastname,
      email: this.email, 
      password: this.password,
      passwordconfirm: this.passwordconfirm,
      phonenumber: this.phonenumber, 
      current: this.userinfo.username,
         }).then(response => {
        console.log(response);
        location.reload()
      })
    },
    getuserinfo(){
      this.$store.dispatch('info').then(response => {
        this.userinfo = response.data;
      })
    },
  },
  mounted(){
    this.getuserinfo()
  }
}
</script>