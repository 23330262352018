<template>
<div>
  <h1 class="text-left ml-4 font-weight-black">Keuze wijziging</h1>
  <v-col cols="auto">
<!--    Update price-->
    <v-btn
        x-large
        color="pink"
        dark
        @click="dialog_price = true"
    >
      Prijs
    </v-btn>
    <v-btn
        x-large
        class="ml-5"
        color="grey"
        dark
    >
      Attributen
    </v-btn>
    <v-btn
        x-large
        class="ml-5"
        color="grey"
        dark
    >
      SKU
    </v-btn>
  </v-col>
  <div>
    <v-dialog v-model="dialog_price" :persistent="true" width="auto">
      <div v-if="!showProgressBar" >
      <v-card width="500" class="black--text" color="white">
        <v-card-title>
          Prijs wijzigen
        </v-card-title>
        <v-card-subtitle class="black--text">
          Vul een prijs in om te bewerken.
        </v-card-subtitle>
        <v-card-text class="pa-2">
          <v-row>
            <v-col>
              <v-text-field
                  v-model="price"
                  :counter="5"
                  label="Prijs wijzigen"
                  required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialog_price = false" text color="red">Sluiten</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="updatePrice" text color="primary">Bewerken</v-btn>
        </v-card-actions>
      </v-card>
      </div>
      <div v-else>
        <v-card v-if="!finished">
          <v-card-title>
            Prijs aan het updaten...
          </v-card-title>
          <v-card-text>
            <h3>Status: {{progress}} / {{prices.length}}</h3>
            <v-progress-linear
                indeterminate
                color="yellow-darken-2"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else-if="clearCached">
          <v-card-title>
            De geüpdatete producten worden gecached...
          </v-card-title>
          <v-card-actions>
            <v-progress-linear
                indeterminate
                color="yellow-darken-2"
            ></v-progress-linear>
          </v-card-actions>
        </v-card>
        <v-card v-else-if="!clearCached && finished">
          <v-card-title>
            Voltooid!
          </v-card-title>
          <v-card-actions>
            <v-btn @click="clearCache" text color="red">Sluiten</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</div>
</template>

<script>
import axios from "@/axios-config";
export default {
  name: "Update",
  props: ['children', 'filtered', 'parent'],
  mounted(){
    console.log(this.filtered)
  },
  data(){
    return{
      absolute: true,
      opacity: 1,
      overlay: true,
      progress: 0,
      /*Update price*/
      dialog_price: false,
      price: null,
      clearCached: false,
      showProgressBar: false,
      finished: false,
      prices: null,
    }
  },
  methods: {
    chunkArray(array, chunkSize) {
      let chunks = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
      }
      return chunks;
    },

    async updatePrice(){
      this.finished = false;
      this.showProgressBar = true;

      this.prices = this.filtered.selectedRows.map((value) => {
        return {id: value.id, price: value.price}
      })

      let batches = this.chunkArray(this.prices, 10);
      for (let i = 0; i < batches.length; i++) {
        try {
          const response = await axios.post('update/variation/price', {
            product: this.parent,
            variations: batches[i],
            price: this.price,
            amount: this.children.length
          });
          this.allFilter = response.data;
          this.progress += batches[i].length;
          this.progress = Math.min(this.progress, this.prices.length);
        } catch (error) {
          console.error(error);
        }
      }
      this.finished = true;
      this.clearCached = true;
      this.statusCache()
    },
    statusCache(){
      const loop = setInterval(() => {
        axios.get('queue-count')
            .then(response => {
              console.log(response)
              if (response.data.count === 0) {
                clearInterval(loop);
                this.clearCached = false;
              }
            })
      }, 2000);
    },
    clearCache(){
      this.finished = true;
      this.$emit('cache-clear');
      this.$emit('filter-chips');
    }
  }
}
</script>

<style scoped>

</style>