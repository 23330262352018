<template>
    <div class="container">
      <h1>Forgot password</h1>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <button class="v-btn" @click = "emailsent();" >Send Email to reset password</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "@/axios-config";
  export default {
    name: "Forgot password",
    data() {
      return {
        email: "",
      };
    },
    methods: {
      emailsent: function ( ) {
         // Handle registration logic here
        axios.post('/changepasswordemail', {

      email: this.email, 
         }).then(response => {
        console.log(response);
        alert("E-mail sent")
        window.location.href = "/";
      })
       
    },
      }
  };
  </script>
  
  <style>
  .container {
    border: 1px solid black ;
    max-width: 100%;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  
  }
  
  h1 {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
  }
  
  input {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  </style>