import axios from "@/axios-config";
const state = {

}

const mutations = {

}

const actions = {
    cache_products(){
        return new Promise((resolve, reject) => {
            //Post request
            axios.get('queue_pages', {
                timeout: 1000,
            }).then(response => {
                console.log(response)
            }).catch(response =>{
                reject(reject)
                console.log(response)
            })
        })
    },
}

export default {
    state,
    mutations,
    actions,
    // getters
}