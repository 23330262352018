<template>
      <v-btn class="float-right ma-3" @click = 'verify'>Verify</v-btn>
</template>
  
  <script>

  import axios from "@/axios-config";
  export default {
    name: 'Login',
      data: () => ({
        email:'',
        password:'',
      }),
    methods:{
        verify(){
      axios.post('verify', {

         }).then(response => {
        console.log(response);
        alert("Account geverifieerd");
        window.location.href = "/";
      })
      },
    }
  
  
  
  }
  </script>
  