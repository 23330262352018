<template>
  <v-container fluid>
    <v-layout column>
      <div v-if="userinfo.accountrank === 5">
        <v-card>
        <v-card-text>
          <v-flex class="mb-4">
          </v-flex>
          <h1>Company account</h1>
          <p> First name:{{userinfo.username}}</p>
          <p> Last name:{{userinfo.userlastname}}</p>
          <p> Email:{{userinfo.email}}</p>
          <p> Phone number:{{userinfo.Phonenumber}}</p>
          <hr><br>

          <v-btn><router-link to="/Update">update data</router-link></v-btn>
          <v-btn @click="deleteAcc" color="red" style="color: black">delete account</v-btn>
          <v-spacer></v-spacer>

          
        </v-card-text>
        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn class="ma-1" color="yellow"> <router-link to="/overviewcoworkers">overview company</router-link>
          </v-btn>
        </v-card-actions>
      </v-card>
      <div v-if="userinfo.verified === 1">
      </div>
      <div v-else>
        <P>Your account is not verified, please click the button below to send an e mail for verification</P>
        <v-btn class="ma-1" color="Blue" @click="verification">verify</v-btn>
      </div>
      </div><!--host check-->
      <div v-else>
      <v-card>
        <v-card-text>
          <v-flex class="mb-4">
          </v-flex>
          <h1>Personal account</h1>
          <p> First name:{{userinfo.username}}</p>
          <p> Last name:{{userinfo.userlastname}}</p>
          <p> Email:{{userinfo.email}}</p>
          <p> Phone number:{{userinfo.Phonenumber}}</p>
          <hr><br>

          <v-btn><router-link to="/Update">update data</router-link></v-btn>

        </v-card-text>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn @click="deleteAcc" color="red" style="color: black">
            delete account
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    </v-layout>
  </v-container>
</template>

<script>
import footerView from "@/components/footerView";
axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
import axios from "@/axios-config";
export default {
  // eslint-disable-next-line vue/no-unused-components
  components:{footerView},
  pageTitle: 'My Profile',
  data() {
    return {
      token: this.$store.state.token,
      userinfo: [
      ],
      companyinfo:[

      ],
      username: "",
      loading: false,
      secretkey: '',
      woocommerce: '',
      webstorelink: '',
    }
  },
  methods:{
    deleteAcc(){
      this.check = prompt("Wil je dit account echt verwijderen? y/n");
      if(this.check === "y"){
      axios.post('/account/delete', {
      id: this.userinfo.id,
         }).then(response => {
        console.log(response);
        localStorage.removeItem('token')
        alert("account deleted");
        window.location.href = "/";
      })
    }
    else{
      alert("Kon account niet verwijderen");
    }
    },
    verification(){
      axios.post('mail_verification_email', {
         }).then(response => {
        console.log(response);
        alert("E-mail verstuurd");
      })
    },
    getuserinfo(){
      this.$store.dispatch('info').then(response => {
        this.userinfo = response.data;
        if(this.userinfo.accountrank === 5){
          this.$store.dispatch('companyinfoadmin').then(response => {
          this.companyinfo = response.data;
        })
        }
      })
    },
  },
  mounted(){
    this.getuserinfo()
  }
}
</script>
<style>
a:hover, a:visited, a:link, a:active
{

    text-decoration: none;
    color:black;
}
</style>
