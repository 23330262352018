import Vue from 'vue'
import Vuex from 'vuex'
import axios from "@/axios-config";
Vue.config.devtools = true
/*style module*/
import style from './modules/style'

/*login module*/
import user from './modules/user'
import products from './modules/products'
Vue.use(Vuex)

export default new Vuex.Store({
  state:{
    token:localStorage.getItem('token'),
  },
  mutations:{
    saveToken(state, token){
      localStorage.setItem('token', token.token);
    },
    updateState(){

    }
  },
  actions:{
    login(context, data){
      return new Promise((resolve, reject) => {
        //Post request
        axios.post('login', {
          email: data[0],
          password: data[1],
          //Result
        }).then(response => {
          context.commit('saveToken', response.data);
          resolve(response.data.token)
        }).catch(response =>{
          reject(response)
        })
      })
    },
    register(context, data){
      return new Promise((resolve, reject) => {
        //Post request
        axios.post('register', {
          username: data[0],
          userlastname: data[1],
          email: data[2],
          password: data[3],
          passwordconfirm:data[4],
          phonenumber: data[5],
          adress: data[6],
          postcode: data[7],
          country: data[8],
          kvk: data[9],
          btw: data[10],
          companyname: data[11],
          companypassword:data[12],
          companypasswordconfirm:data[13],
          
          //Result
        }).then(response => {
          context.commit('saveToken', response.data);
          resolve(response.data.token)
        }).catch(response =>{
          reject(response)
        })
      })
    },
    info(){
      return new Promise((resolve,reject) =>{
        axios.post('info').then(response =>{
          resolve(response)

        }).catch(response =>{
          reject(response)
        })
      })
    },
    companyinfoadmin(){
      return new Promise((resolve,reject) =>{
        axios.post('companyinfoadmin').then(response =>{
          resolve(response)

        }).catch(response =>{
          reject(response)
        })
      })
  },




  },

  modules: {
    style: style,
    user: user,
    products: products,
  },

})
