<template>

<nav>

  <v-navigation-drawer v-model="drawer" app class = "sidebar">
    <v-icon name = "toolbar" class = "my-5"  @click = "drawer = !drawer">mdi-close</v-icon>
    <br>
    <div v-if="this.$store.state.token">
    <v-btn class = "mt-2" text ><router-link to="/profile">profile foto*</router-link></v-btn><br>

    <v-btn class = "mb-7" text ><router-link to="/profile">{{userinfo.username}}</router-link></v-btn>
    <br>
    </div>
    <div v-if="this.$store.state.token">
    <v-btn class = "my-5" text ><router-link to="/">Home</router-link></v-btn>
    <br>

    <v-btn class = "my-5" text ><router-link   to="/product">Product info</router-link></v-btn>
    <br>

    <v-btn class = "my-5" text ><router-link to="/contact">Contact</router-link></v-btn>
    <br>

    <v-btn class = "my-5" text  ><router-link to="/profile">Profile</router-link></v-btn>
    <br>


    <br>
    </div>
    <div v-else>
      <p>Not logged in</p>

      <v-btn class = "my-5" text ><router-link to="/login">Home</router-link></v-btn>
      <br>

      <v-btn class = "my-5" text ><router-link   to="/login">Product info</router-link></v-btn>
      <br>

      <v-btn class = "my-5" text ><router-link to="/login">Contact</router-link></v-btn>
      <br>

      <v-btn class = "my-5" text  ><router-link to="/login">Profile</router-link></v-btn>
      <br>


      <br>
    </div>
    <div v-if="this.$store.state.token">
      <v-btn @click="logout">Logout</v-btn>
    </div>
  </v-navigation-drawer>

</nav>
</template>

<script>
import axios from "@/axios-config";
import { bus } from '../main';
export default {

  name: "Indexmenu",
  data() {
    return {
      drawer: false,
      token: this.$store.state.token,
      userinfo: [

      ]
    }
  },
  watch: {
    token(newValue) {
      if (newValue) {
        this.reloadPage()
      }
    }
  },

  methods: {
    reloadPage() {
      this.$router.go(0);
    },
    logout() {
      localStorage.removeItem('token')
      this.$router.push({ name: 'home' })
      this.$store.state.token = null;
      axios.post('logout').then(() => {

      }).catch(response =>{
        console.log(response)
      })
    },
    getuserinfo(){
      this.$store.dispatch('info').then(response => {
       this.userinfo = response.data;
      })
    }

  },
  created(){
    bus.$on('menuchange', (data) =>{
      this.drawer = true;
      console.log(data);
      this.getuserinfo()
    })
  }

}

</script>

